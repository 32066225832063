@import '../../styles/index';

.base {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 3rem;
  justify-content: center;
  margin: 0 auto 0;
  max-width: 64rem;
  position: relative;
  text-align: center;
  width: 100%;
}

.horizontal-check-list {
  @extend %list-checkmark;
  align-items: flex-start;

  display: inline-flex;
  flex-direction: column;
  gap: 1rem;
  margin: 0 auto;
  text-align: left;

  li {
    font-size: $font-size-20;
    line-height: 1.5;
    margin: 0;
  }

  @include media-from(sm) {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1rem;

    justify-content: space-between;
    width: 100%;
  }
}

.center {
  margin-inline: auto;
  width: auto;
}

.customer-support {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
  justify-content: center;
}

.flags {
  display: flex;
  flex-wrap: wrap;
  gap: 0.875rem;
  width: auto;
}

.emma-photo {
  width: 234px;
}

.headline {
  max-width: 1024px;
}

$badge-delta: 680px - 590px; // screen widths

.badge {
  align-items: center;
  aspect-ratio: 1 / 1;
  background-color: $color-yellow--100;
  border-radius: 50%;
  color: #fff;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  font-size: clamp($font-size-12, 3.5vw, $font-size-14);
  justify-content: center;
  line-height: 1.2;
  overflow: hidden;
  position: absolute;
  right: calc(-1 * clamp(12px, calc(18vw - $badge-delta), 50px));
  top: calc(-1 * clamp(50px, 9vw, 50px));
  transform: rotate(15deg);
  width: clamp(120px, 18vw, 144px);
  z-index: 2;
}

.highlight {
  font-size: clamp($font-size-20, 3.5vw, $font-size-36);
  font-weight: $font-weight-bold;
}

.screen-wrapper {
  margin: 54px auto 0;
  max-width: 540px;
  position: relative;
  width: 100%;
}

.screen-box {
  aspect-ratio: 540 / 320;
  background: $color-white--100 url('./assets/solar-chat-teaser-jan.jpg')
    no-repeat center / cover;
  border-radius: 26px;
  box-shadow: $shadow--large;
  color: $color-white--100;
  display: flex;
  flex-direction: column;
  font-size: $font-size-20;
  font-weight: 400;
  justify-content: flex-start;
  line-height: 1.3;
  padding: 24px 20px;
  position: relative;
  text-align: left;
  width: 100%;

  @include media-to(xs) {
    & > * {
      display: none;
    }
  }

  strong {
    color: $color-surrogate--100;
    font-weight: $font-weight-bold;
  }
}

.message-bubble {
  background-color: $color-white--100;
  border-radius: 20px;
  box-shadow: $shadow--large;
  display: block;
  left: 1rem;
  opacity: 0;
  padding: 1.5rem;
  position: relative;
  text-align: left;
  top: -25px;

  transition:
    margin $duration-long,
    top $duration-long,
    left $duration-long;
  width: calc(100% - 2rem);
  z-index: 2;

  @include media-from(sm) {
    left: -85px;
    margin-bottom: -30px;
    margin-inline: auto;
    top: -45px;
    width: 480px;
  }

  &::before {
    background: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="28" height="23" viewBox="0 0 28 23" fill="none"><path d="M21.1716 1.82842L0 23H28V4.65685C28 1.09324 23.6914 -0.691439 21.1716 1.82842Z" fill="white"/></svg>')
      no-repeat center bottom;
    content: '';
    display: block;
    height: 23px;
    margin-left: 50%;
    position: absolute;

    transform: translate(-200%, calc(-100% - 1.45rem));

    transition: transform $duration-long;
    width: 28px;

    @include media-from(sm) {
      transform: translate(0%, calc(-100% - 1.45rem));
    }
  }

  &.hide {
    opacity: 0;
  }

  &.animate {
    animation: appear 1s 0.25s forwards;
  }
}

.disable-link {
  pointer-events: none;
}

.footer {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin-top: 0.5rem;
}

@keyframes appear {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: scale(1.2);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
